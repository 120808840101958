<template>
  <b-card>
    <div>
      <validation-observer ref="accountRules" tag="form">
        <b-form class="mt-1" @submit.prevent>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Data Role
              </h5>
            </b-col>
            <b-col md="6">  
              <validation-provider #default="{ errors }" name="roleid" rules="required">
                <b-form-group label="Role" label-for="roleid" :state="errors.length > 0 ? false : null">
                  <v-select id="roleid" v-model="dataParent.roleid" :reduce="(roleid) => roleid.real_id"
                    placeholder="Pilih Role" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="ListRoles"
                    label="rolename" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>            
              <validation-provider #default="{ errors }" name="menuid" rules="required">
                <b-form-group label="Menu" label-for="menuid" :state="errors.length > 0 ? false : null">
                  <v-select id="menuid" v-model="dataParent.menuid" :reduce="(menuid) => menuid.real_id"
                    placeholder="Pilih Menu" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="ListMenus"
                    label="title" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                @click="formSubmitted">
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                @click="() => $router.go(-1)">
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      required,
      email,
      size,      
      dataParent: {
        menuid: '',
        roleid: '',
        notes: '',
        active: '',
      },
      ListMenus: [],
      ListRoles: [],
    }
  },
  computed: {

  },
  created() {
    this.getRoles(),
      this.getMenus()
  },
  methods: {
    async getRoles() {
      try {
        const response = await this.$http.get('/roledatas')
        this.ListRoles = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getMenus() {
      try {
        const response = await this.$http.get('/menus')
        this.ListMenus = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        await this.$http.post('/rolemenus', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'role-data' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Role Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
